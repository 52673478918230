<template>
    <div class="Setting">
        <div class="buyLog scrollBox">
            <template>
                <dl>
                    <div>
                        <dd>
                            <span style="color: #303133">{{dataDatail.companyname}}</span>
                            <em class="wait">{{dataDatail.isusedName}}</em>
                        </dd>

                        <dd v-show="dataDatail.desc != []" style="flex-direction: column">
                            <span v-for="(ele,index) in dataDatail.desc" :key="index" class="desc">{{ele}}</span>
                        </dd>
                    </div>
                    <div class="detailList">
                        <dd class="line">
                            <!-- 所属机构 -->
                            <span>{{$t('mx_mobile.Client.1585282110658')}}</span>
                            <em>上海孚盟软件有限公司</em>
                        </dd>
                        <dd class="line">
                            <!-- 业务员 -->
                            <span>{{$t('mx_mobile.Client.1585282251381')}}</span>
                            <em>{{dataDatail.saleName}}</em>
                        </dd>
                    </div>
                </dl>
                <dl style="padding-top: 0;">
                    <div class="detailList">
                        <dd class="line" style="border-top: none">
                            <!-- 订单编号 -->
                            <span>{{$t('mx_mobile.Client.1585283168735')}}</span>
                            <em>{{dataDatail.orderno}}</em>
                        </dd>
                        <dd class="line">
                            <!-- 下单时间 -->
                            <span>{{$t('mx_mobile.Client.1585283178807')}}</span>
                            <em>{{dataDatail.createdate}}</em>
                        </dd>
                        <dd class="line">
                            <!-- 应付金额 -->
                            <span>{{$t('mx_mobile.Client.1585282254169')}}</span>
                            <em>￥{{returnFloat(dataDatail.payamount)}}</em>
                        </dd>
                        <dd class="line">
                            <!-- 实付金额 -->
                            <span>{{$t('mx_mobile.Client.1585282263945')}}</span>
                            <em class="red">￥{{returnFloat(dataDatail.actualreceiveamount)}}</em>
                        </dd>
                        <dd class="line" v-if="dataDatail.isused == 0">
                            <!-- 开通链接 -->
                            <span>{{$t('mx_mobile.Client.1585283325447')}}</span>
                            <em class="link">
                                <span id="foo2">{{dataDatail.authurl}}</span>
                                <!-- 复制 -->
                                <button @click="Copy2" data-clipboard-target="#foo2" ref="copy2" class="copy" v-show="dataDatail.authurl!=''">{{$t('mx_mobile.Client.1585285266106')}}</button>
                            </em>
                        </dd>
                        <dd class="line" v-if="dataDatail.isused == 0">
                            <!-- 许可号 -->
                            <span>{{$t('mx_mobile.Client.1585283317065')}}</span>
                            <em>
                                <span id="foo1">{{dataDatail.offlineauthno}}</span>
                                <!-- 复制 -->
                                <button @click="Copy1" data-clipboard-target="#foo1" ref="copy1" class="copy" v-show="dataDatail.offlineauthno!=''">{{$t('mx_mobile.Client.1585285266106')}}</button>
                            </em>
                        </dd>
                    </div>
                </dl>
                <dl style="padding-top: 0;">
                    <div class="detailList">
                        <dd class="line" style="border-top: none">
                            <!-- 服务人员 -->
                            <span>{{$t('mx_mobile.Client.1585283400806')}}</span>
                            <em>{{dataDatail.saleName}}</em>
                        </dd>
                        <dd class="line">
                            <!-- 手机号码 -->
                            <span>{{$t('mx_mobile.Client.1585283400965')}}</span>
                            <em>{{dataDatail.servicePhone}}</em>
                        </dd>
                    </div>
                </dl>
                <van-tabs v-model="activePay" style="margin-top: 10px;" v-if="dataDatail.isused == -1">
                    <!-- 扫码付款 -->
                    <van-tab :title="$t('mx_mobile.Client.1585283401134')">
                        <div style="padding: 10px 0;text-align:center;background: #fff">
                            <img width="200" height="200" src="/images/pay/zhifubao.png" />
                            <!-- 支付宝扫一扫，立即付款 -->
                            <p>{{$t('mx_mobile.Client.1585283396231')}}</p>
                        </div>
                    </van-tab>
                    <!-- 线下汇款 -->
                    <van-tab :title="$t('mx_mobile.Client.1585283439142')">
                        <div style="padding: 10px 0;">
                            <!-- 开户名称 -->
                            <van-cell :title="$t('mx_mobile.Client.1585284627987')" value="上海孚盟软件有限公司" />
                            <!-- 开户银行  -->
                            <van-cell :title="$t('mx_mobile.Client.1585284645889')" value="平安银行上海西南支行" />
                            <!-- 银行账户 -->
                            <van-cell :title="$t('mx_mobile.Client.1585284646072')" value="11006935635401" />
                        </div>
                    </van-tab>
                </van-tabs>
            </template>
        </div>
        <!-- 取消订单 -->
        <van-button type="primary" @click="delItem()" size="large" class="delList" v-if="dataDatail.isused == -1">{{$t('mx_mobile.Client.1585284750033')}}</van-button>
    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
import Clipboard from 'clipboard'
export default {
    name: 'Setting',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Client.1585284810669') }, // '订单详情'
    data() {
        return {
            dataDatail: {},
            activePay: 0
        }
    },
    created() {
        // this.pageUrl = this.$route.path
        this.getDetail()
        this.setMenu()
    },
    methods: {
        Copy1() {
            let clipboard = new Clipboard(this.$refs.copy1) // 实例化对象
            let that = this
            clipboard.on('success', (e) => {
                that.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                e.clearSelection() // 清空粘贴区1
            })
        },
        Copy2() {
            let clipboard = new Clipboard(this.$refs.copy2) // 实例化对象
            let that = this
            clipboard.on('success', (e) => {
                that.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                e.clearSelection() // 清空粘贴区1
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        },
        delItem() {
            let { id } = this.$route.params
            this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.payCenter_onlineOrder, { orderno: id }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    this.$toast(this.$t('mx_mobile.Client.1585284881312')) // '该订单取消成功'
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getDetail() {
            this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585281647545') // '加载中...'
            })
            let { id } = this.$route.params
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mxorderDetail, { params: { orderno: id } }).then(res => {
                this.$toast.clear()
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.dataDatail = res.data.data.entity
                    this.dataDatail.desc = this.dataDatail.desc.split(',')
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                this.$toast.clear()
                console.log(error)
            })
        }
    },
    watch: {
        // $route(to, from) {
        //     if (to.path === this.pageUrl) {
        //         this.setMenu()
        //     }
        // }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.delList {
    position: fixed;
    bottom: 0;
    border-color: #d0021b;
    background: #d0021b;
    color: #fff;
    width: 100%;
}
.Setting {
    // position: fixed;
    // left: 0;
    // right: 0;
    // top: 3.2em;
    // bottom: 3.5em;
    // overflow-y: auto;
    // z-index: 9999;
    display: flex;
    width: 100%;
}
.scrollBox {
    position: absolute;
    .top(0);
    left: 0;
    right: 0;
    bottom: 50px;
    overflow: auto;
}
.buyLog {
    flex: 1;

    > dl {
        background: #fff;
        padding: 10px 15px 0 15px;
        margin: 10px 0 0;
        color: #909399;
        dt,
        dd {
            display: flex;
            justify-content: space-between;
            margin: 8px 0;
        }
        dt {
            color: #303133;
            font-size: 14px;
        }
        .state {
            border-top: 1px solid #eee;
            padding: 5px 0 0;
            margin: 15px 0 0;
            > div {
                padding-top: 12px;
                em {
                    color: #d0021b;
                }
            }
        }
        .wait {
            font-weight: bold;
            color: #d0021b;
        }
        .end {
            font-weight: bold;
            color: #00c2b3;
        }
        .line {
            border-top: 1px solid #eaedef;
            height: 40px;
            line-height: 40px;
            margin: 0;
        }
        .copy {
            margin-left: 5px;
            background: #fff;
            padding: 0 4px;
            border: 1px solid #d0021b;
            color: #d0021b;
            border-radius: 3px;
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            margin-top: 10px;
        }
        .link {
            overflow: hidden;
            #foo2 {
                max-width: 132px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                height: 40px;
                float: left;
            }
            button {
                float: left;
            }
        }

        .detailList {
            dd {
                span {
                    flex: 1;
                }
                em {
                    flex: 2;
                    color: #303133;
                }
                .red {
                    color: #d0021b;
                }
            }
        }
    }
}
</style>
